import { Disclosure as MainDisclosure, Transition } from "@headlessui/react"
import { FC, Fragment } from "react"
import { BiCaretDown } from "react-icons/bi"
import { AccordionProp } from "@lib/data/_types"

type DisclosureProps = {
    accordion: AccordionProp[]
}
const Disclosure: FC<DisclosureProps> = ({ accordion }) => {
    if (accordion.length === 0) return null
    return (
        <>
            {accordion.map((item, index) => (
                <MainDisclosure
                    as="div"
                    key={`accordion-${index}`}
                    defaultOpen={item?.initialPosition ?? false}
                    className={"mb-4"}
                >
                    {({ open }) => (
                        <>
                            <MainDisclosure.Button as={Fragment}>
                                <h2
                                    className={
                                        "text-xl font-semibold cursor-pointer py-2 border-b border-darkTone flex justify-between"
                                    }
                                >
                                    <span>{item?.title ?? ""}</span>
                                    <BiCaretDown
                                        className={`transition-transform duration-300 transform ${
                                            open ? "rotate-180" : ""
                                        } inline-block ml-1`}
                                    />
                                </h2>
                            </MainDisclosure.Button>
                            <Transition
                                show={open}
                                unmount={false}
                                className="transition-all origin-top duration-500 overflow-hidden"
                                enterFrom="transform scale-95 opacity-0 max-h-0"
                                enterTo="transform scale-100 opacity-100 max-h-[1000px]"
                                leaveFrom="transform scale-100 opacity-100 max-h-[1000px]"
                                leaveTo="transform scale-95 opacity-0 max-h-0"
                            >
                                <MainDisclosure.Panel
                                    static
                                    className={"mt-2"}
                                    dangerouslySetInnerHTML={{
                                        __html: item?.content ?? "",
                                    }}
                                ></MainDisclosure.Panel>
                            </Transition>
                        </>
                    )}
                </MainDisclosure>
            ))}
        </>
    )
}

export default Disclosure
